import Language from '../utils/lang';
import React, { Component } from 'react';

export default class Login extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang,
            size: 1000
        };

    }

    componentDidMount(){
        window.onmessage = function (e) {
            if (typeof e.data == "string" && e.data.includes("size")) {
                this.setState({ size: Number.parseFloat(e.data.substr(e.data.indexOf(':') + 1)) })
            }
        }.bind(this);
        window.onresize = this.onIframeLoad.bind(this);
    }

    componentWillUnmount() {
        window.onmessage = null;
        window.onresize = null;
    }

    onIframeLoad() {
        this.refs.iframe.contentWindow.postMessage('size', '*');
        setTimeout((() => {
            if (this.refs.iframe) {
                this.refs.iframe.contentWindow.postMessage('size', '*');
            }
        }).bind(this), 2000);
    }

    render() {
        return (
            <div>
                <iframe src={process.env.REACT_APP_MPOS_LINK + "account/login"}
                    frameBorder="0" scrolling="no" width="100%" height={this.state.size} ref="iframe" onLoad={this.onIframeLoad.bind(this)} />
            </div>
        )
    }
}