import Language from '../utils/lang';
import React, { Component } from 'react';
import PageBanner from '../components/pageBanner';
import './payCard.css';
import BackgroundImage1 from '../images/imgpsh_fullsize.png';
import BackgroundImage2 from '../images/imgpsh_fullsize_1.png';
import BackgroundImage3 from '../images/imgpsh_fullsize_2.png';

export default class PayCard extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }

    render() {
        return (
            <div>
                <PageBanner src={require("../images/BANNER - card payment_V6.jpg")}>
                    <span>{Language.VE("Thanh toán", "")}
                        <br className="d-block d-sm-none" />
                        <b className="ml-sm-3">{Language.VE("Thẻ", "")}</b>
                    </span>
                </PageBanner>

                <div className="p-t-header-pay-card">
                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1" style={{
                            backgroundImage: `url(${BackgroundImage1})`, backgroundRepeat: 'no-repeat',
                            paddingBottom: '100px', backgroundSize: 'auto 100%'
                        }}>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img style={{ maxWidth: '100%', maxHeight: '500px', marginBottom: '30px' }} src={require('../images/1.login.jpg')} />
                                    </div>
                                    <div className="col-md-6">
                                        <img style={{ maxWidth: '100%', maxHeight: '500px' }} src={require('../images/2.home.jpg')} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-1">
                                        <div>
                                            <h1 className="h1-pay-card">Bước 1</h1>
                                        </div>
                                        <div className="text-align-step-1">
                                            <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/1. dangnhap.svg')} />
                                            <div style={{ fontSize: '20px', marginTop: '10px' }}>Đăng nhập vào tài khoản PayoomPOS.<br /> Chọn giao dịch thanh toán.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1">
                            <div className="col-lg-3">
                                <div className="text-align-step-2 m-t-step-2-pay-card">
                                    <div>
                                        <h1 className="h1-pay-card">Bước 2</h1>
                                    </div>
                                    <div className="text-align-step-2">
                                        <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/2. nhaptien.svg')} alt="One slide" />
                                        <div style={{ fontSize: '20px', marginTop: '10px' }}>Nhập số tiền cần thanh toán.<br /> Chọn hình thức thanh toán thẻ.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 style-image-step-2" style={{ backgroundImage: `url(${BackgroundImage2})` }}>
                                <div className="row">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-md-6 text-align-step-2-image-order">
                                                <img style={{ maxWidth: '100%', maxHeight: '500px', marginBottom: '30px' }} src={require('../images/3.donhang.jpg')} />
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ maxWidth: '100%', maxHeight: '500px' }} src={require('../images/5.chon hinh thuc thanh toan.jpg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-t-step-3 p-l-10rem style-image-step-3" style={{ backgroundImage: `url(${BackgroundImage3})` }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <img className="img-style-step-3-pay-card" src={require('../images/9.ky ten.jpg')} alt="One slide" />
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-3">
                                        <div>
                                            <h1 style={{ marginTop: '0' }}>Bước 3</h1>
                                        </div>
                                        <div className="text-align-step-3 pr-5">
                                            <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/3. kyten.svg')} alt="One slide" />
                                            <div style={{ fontSize: '20px', marginTop: '10px' }}>Ký tên xác nhận và hoàn tất thanh toán.<br /> Nhận thông tin thanh toán qua email.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner-content-product mpos-product">
                                <ul className="icon-ul">
                                    <li className="text-align-left">
                                        Thiết bị nhỏ gọn, cho phép di chuyển nhiều nơi
                        </li>
                                    <li className="text-align-left">
                                        Tính bảo mật cao
                        </li>
                                    <li className="text-align-left">
                                        Chi phí đầu tư và duy trì thấp
                        </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <img style={{
                                width: '550px', maxHeight: '550px', maxWidth: '100%', maxHeight: '100%'
                            }} src={require('../images/dongle_1.png')} alt="One slide" />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <img style={{ maxWidth: '90%', maxHeight: '350px', marginBottom: '30px' }} src={require('../images/card.png')} alt="One slide" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-lg-12 banner-content-product">
                                    <ul className="icon-ul">
                                        <li className="text-align-left">
                                            Liên kết nhiều ngân hàng
                                        </li>
                                        <li className="text-align-left">
                                            Hỗ trợ nhiều loại thẻ: thẻ nội địa, thẻ quốc tế
                                        </li>
                                        <li className="text-align-left">
                                            An toàn khi thanh toán
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}