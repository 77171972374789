import Language from '../utils/lang';
import React, { Component } from 'react';
import './manual.css';
import PageBanner from '../components/pageBanner';

export default class Manual extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }

    render() {
        return (
            <div>
                <PageBanner src={require("../images/BANNER - user manual_V6.jpg")}>
                    <span>{Language.VE("Sử dụng", "")}
                        <br className="d-block d-sm-none" />
                        <b className="ml-sm-3">{Language.VE("PayoomPOS", "")}</b>
                    </span>
                </PageBanner>

                <div className="col-lg-12 col-md-12" style={{marginTop: '50px'}}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 content-download-app">
                            <button className="download-document-app">
                                <a href="#" className="link">
                                    <img style={{width: '100px', maxHeight: '100px', marginBottom: '20px'}} src={require('../images/8. hdsd website.svg')} alt="One slide"/>
                                    <div>Hướng dẫn sử dụng <br/><b>App PayoomPOS</b></div>
                                </a>
                            </button>
                        </div>

                        <div className="col-lg-6 col-md-6 content-download-web">
                            <button className="download-document-app">
                                <a href="#" className="link">
                                    <img style={{width: '100px', maxHeight: '100px', marginBottom: '20px'}} src={require('../images/7. hdsd app.svg')} alt="One slide"/>
                                    <div>Hướng dẫn sử dụng <br/><b>Website</b></div>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12">
                    <button className="button-question">
                        <a href="#/question" className="link">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <h2 className="question" style={{color:'rgb(7,84,142)'}}>Các câu hỏi thường gặp</h2>
                                <img className="d-none d-sm-block" style={{width: '15px', maxHeight: '30px', marginTop: '7px', marginLeft: '10px'}} src={require('../images/arrow.jpg')} alt="One slide"/>
                            </div>
                        </a>
                    </button>
                </div>
            </div>
        )
    }
}