import React, { Component } from 'react';
import './pageBanner.css';

export default class PageBanner extends Component {
    render() {
        return (
            <div>
                <div className="justify-content-center align-items-center banner" 
                        style={{ backgroundImage: `url("${this.props.src}")`, }}>
                    <div className="banner-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}