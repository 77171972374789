import Language from '../utils/lang';
import React, { Component } from 'react';
import './payQr.css';
import PageBanner from '../components/pageBanner';
import BackgroundImage1 from '../images/imgpsh_fullsize.png';
import BackgroundImage2 from '../images/imgpsh_fullsize_1.png';
import BackgroundImage3 from '../images/imgpsh_fullsize_2.png';

export default class PayQR extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }

    render() {
        return(
            <div>
                <PageBanner src={require("../images/BANNER - QR code_V6.jpg")}>
                    <span>{Language.VE("Thanh toán", "")}
                        <br className="d-block d-sm-none" />
                        <b className="ml-sm-3">{Language.VE("Mã QR", "")}</b>
                    </span>
                </PageBanner>
                <div className="p-t-header-pay-qr">
                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1" style={{backgroundImage: `url(${BackgroundImage1})`, backgroundRepeat: 'no-repeat',
                        paddingBottom: '100px', backgroundSize: 'auto 100%'}}>
                            <div className="col-lg-6"> 
                                <div className="row">
                                    <div className="col-md-6">
                                        <img style={{maxWidth: '100%', maxHeight: '500px', marginBottom: '30px'}} src={require('../images/1.login.jpg')}/>
                                    </div>
                                    <div className="col-md-6">
                                        <img style={{maxWidth: '100%', maxHeight: '500px'}} src={require('../images/2.home.jpg')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-1">
                                        <div>
                                            <h1 className="h1-pay-card">Bước 1</h1>
                                        </div>
                                        <div className="text-align-step-1">
                                            <img style={{width: '100px', maxHeight: '100px'}} src={require('../images/1. dangnhap.svg')}/>
                                            <div style={{fontSize: '20px', marginTop: '10px'}}>Đăng nhập vào tài khoản PayoomPOS.<br/> Chọn giao dịch thanh toán.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1">
                            <div className="col-lg-3">
                                <div className="text-align-step-2 m-t-step-2">
                                        <div>
                                            <h1 className="h1-pay-card">Bước 2</h1>
                                        </div>
                                        <div className="text-align-step-2">
                                            <img style={{width: '100px', maxHeight: '100px'}} src={require('../images/5. thanhtoanQR.svg')} alt="One slide"/>
                                            <div style={{fontSize: '20px', marginTop: '10px'}}>Nhập số tiền cần thanh toán.<br/> Chọn hình thức thanh toán mã QR.</div>
                                        </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-9 style-image-step-2" style={{backgroundImage: `url(${BackgroundImage2})`}}>
                                <div className="row">
                                    <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <div className="row">
                                                <div className="col-md-6 text-align-step-2-pay-installment">
                                                    <img style={{maxWidth: '100%', maxHeight: '500px', marginBottom: '30px'}} src={require('../images/3.donhang.jpg')}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <img style={{maxWidth: '100%', maxHeight: '500px'}} src={require('../images/5.chon hinh thuc thanh toan.jpg')}/>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-t-step-3 style-image-step-3" style={{backgroundImage: `url(${BackgroundImage3})`}}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-4">
                                        <img className="img-style-step-3" src={require('../images/phone-03.png')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-3 m-t-step-3">
                                        <div>
                                            <h1 style={{marginTop: '0'}}>Bước 3</h1>
                                        </div>
                                        <div className="text-align-step-3">
                                            <img style={{width: '100px', maxHeight: '100px'}} src={require('../images/6. quetmaQR.svg')} alt="One slide"/>
                                            <div style={{fontSize: '20px', marginTop: '10px'}}>Dùng app quét mã QR<br/> để hoàn tất thanh toán.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12" style={{marginTop: '80px'}}>
                        <div className="col-lg-4 offset-lg-4 banner-content-product" style={{textAlign: 'center'}}>
                            <ul className="icon-ul">
                                <li className="text-align-left">
                                    Hình thức thanh toán hiện đại và an toàn.
                                </li>
                                <li className="text-align-left">
                                    Hỗ trợ thanh toán ngay cả khi khách hàng không mang thẻ và tiền mặt.
                                </li>
                                <li className="text-align-left">
                                    Hỗ trợ chuẩn Mastercard QR.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <img className="mt-5" style={{height: '300px', maxWidth: '100%', objectFit: 'contain'}} src={require('../images/masterpassQR.jpg')} alt="One slide"/>
                </div>
            </div>
        )
    }
}