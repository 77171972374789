import Language from '../utils/lang';
import React, { Component } from 'react';
import PageBanner from '../components/pageBanner';
import './registration.css';

export default class Registration extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang,
            size: 500
        };



    }

    componentDidMount(){
        window.onmessage = function (e) {
            if (typeof e.data == "string" && e.data.includes("size")) {
                this.setState({ size: Number.parseFloat(e.data.substr(e.data.indexOf(':') + 1)) })
            }
        }.bind(this);
        window.onresize = this.onIframeLoad.bind(this);
    }

    componentWillUnmount() {
        window.onmessage = null;
        window.onresize = null;
    }

    onIframeLoad() {
        this.refs.iframe.contentWindow.postMessage('size', '*');
        setTimeout((() => {
            if (this.refs.iframe) {
                this.refs.iframe.contentWindow.postMessage('size', '*');
            }
        }).bind(this), 2000);
    }

    render() {
        return (
            <div>
                <PageBanner src={require("../images/BANNER - Sign up_V6.jpg")}>
                    <span>{Language.VE("Đăng ký trở thành", "")}
                        <br className="d-block d-sm-none" />
                        <b className="ml-sm-3">{Language.VE("Đại lý", "")}</b>
                    </span>
                </PageBanner>
                <h1>{Language.VE("Quy trình Đăng Ký", "Register Process")}</h1>
                <div className="container" style={{ marginBottom: '5rem' }}>
                    <div className="row">
                        <div className="col-xs-12 col-lg-4 col-md-6">
                            <div className="diagram mb-4">
                                <h2>{Language.VE("Bước 1", "")}</h2>
                                <img src={require('../images/6. Sign up page_V6-04.svg')}></img>
                                <br />
                                <p>
                                    {Language.VE("Liên hệ hotline")}<strong className="phone">1900.54.54.78</strong> {Language.VE("để được tư vấn về sản phẩm, điều kiện và điều khoản.")}
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-lg-4  col-md-6">
                            <div className="diagram mb-4">
                                <h2>{Language.VE("Bước 2", "")}</h2>
                                <img src={require('../images/6. Sign up page_V6-02.svg')}></img>
                                <br />
                                <span>
                                    {Language.VE("Ký hợp đồng, nhận thiết bị đọc thẻ, tài khoản và tham gia các lớp hướng dẫn.")}
                                </span>
                            </div>


                        </div>

                        <div className="col-xs-12 col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="diagram mb-4">
                                <h2>{Language.VE("Bước 3", "")}</h2>
                                <img src={require('../images/6. Sign up page_V6-03.svg')}></img>
                                <br />
                                <span>
                                    {Language.VE("Kết nối và triển khai dịch vụ")}
                                </span>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="pt-5 pb-5" style={{ backgroundImage: `url("${require('../images/sign up - table.jpg')}")`, width: '100%', backgroundSize: 'cover' }}>
                    <div className="row">
                        <div className="col-10 offset-1 col-xl-4 offset-xl-4 col-md-8 offset-md-2 col-lg-6 offset-lg-3 signup-panel pl-5 pr-5" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
                            <h2 className="sub-title">Đăng ký nhanh</h2>
                            <iframe src={process.env.REACT_APP_MPOS_LINK + "Home/Register"}  id='form'
                                frameBorder="0" scrolling="no" width="100%" height={this.state.size} ref="iframe" onLoad={this.onIframeLoad.bind(this)} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}