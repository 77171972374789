class VI {
    introduction = 'Giới thiệu'
    product = "Sản phẩm"
    flowRegister = "Quy trình đăng ký"
    userManual = "Hướng dẫn sử dụng"
    payCard = "Thanh toán thẻ"
    payInstallment = "Thanh toán trả góp"
    payQR = "Thanh toán QR"
    register = "Đăng ký"
}

class EN {
    home = "Home"

}
export default class Language {
    static _VI = new VI();
    static _EN = new EN();

    static _currentLang = null;

    /**
    * @returns {(0|1)} Current Language Type - 0: Vietnamese, 1: English
    */
    static get CurrentLangType() {
        if (!Language._currentLang) {
            if (!localStorage.lang) {
                localStorage.lang = 0
            };
            Language._currentLang = Number.parseInt(localStorage.lang);
        }
        return Language._currentLang;
    }

    static set CurrentLangType(value) {
        localStorage.lang = value;
        Language._currentLang = value;
    }

    /**
    * @returns {(VI|EN)} Current Language
    */
    static get CurrentLang() {
        return !Language.CurrentLangType ? Language._VI : Language._EN;
    }

    /**
     * Return Vietnamese or English depend on current language
     * @param {string} vietnameses
     * @param {string} english
    */
    static VE(vietnameses, english) {
        return !Language.CurrentLangType ? vietnameses : english;
    }
}