import Language from '../utils/lang';
import React, { Component } from 'react';
import './home.css';

class PhoneGuide extends React.PureComponent {

  data = [
    {
      title: Language.VE("Đăng nhập ứng dụng"),
      phone: require('../images/phone-01.png'),
      step: require('../images/phone-step-1.png'),
      details: [
        Language.VE("Đăng nhập vào tài khoản PayoomPOS."),
        Language.VE("Nhập thông tin giao dịch."),
        Language.VE("Chọn hình thức thanh toán theo yêu cầu Khách hàng.")
      ],
      isActive: true
    },
    {
      title: Language.VE("Thực hiện thanh toán"),
      phone: require('../images/phone-03.png'),
      step: require('../images/phone-step-2.png'),
      details: [
        Language.VE("Thực hiện quét mã QR trên ứng dụng hoặc thực hiện quẹt thẻ từ/ chèn thẻ chíp trên thiết bị đọc thẻ."),
        Language.VE("PayoomPOS chấp nhận đa dạng các loại thẻ: thẻ từ, thẻ chíp và thanh toán di động (QR code).")]
    },
    {
      title: Language.VE("Hoàn tất"),
      phone:           require('../images/phone-02.png'),
      step:            require('../images/phone-step-3.png'),
      details:            [
                    Language.VE("Khách hàng xác nhận thông tin và thanh toán."),
                    Language.VE("PayoomPOS còn hỗ trợ các công cụ quản lý/ thống kê: Xem lại lịch sử các giao dịch đã thực hiện; Hủy giao dịch, cho phép hoàn tiền về thẻ khách hàng khi cần hoàn trả; Tổng kết giao dịch, kết toán tất cả các giao dịch thanh toán có trên PayoomPOS.")]
                
    }
  ]

  renderPhoneStep(img) {
    return (
      <div className="phone-step" >
        <img width="250" src={img} style={{ position: 'absolute' }} alt=""/>
        <div style={{ display: 'inline-flex', position: 'relative' }}>
          <div className='btn' data-target="#carouselPhoneFade" data-slide-to="0" style={{ background: 'transparent', width: 83, height: 57 }}>
          </div>
          <div className='btn' data-target="#carouselPhoneFade" data-slide-to="1" style={{ background: 'transparent', width: 83, height: 57 }}>
          </div>
          <div className='btn' data-target="#carouselPhoneFade" data-slide-to="2" style={{ background: 'transparent', width: 83, height: 57 }}>
          </div>
        </div>
      </div>
    )
  }

  renderPhone(title, image, step, details, isActive) {
    return (
      <div key={title} className={"carousel-item phone-show" + (isActive ? " active" : "")}>
        {
          this.renderPhoneStep(step)
        }

        <div className="phone-detail-border">
          <div className="phone-detail">
            <h4>{title}</h4>
            <ul>
              {
                details.map((u, i) => <li key={i}>{u}</li>)
              }
            </ul>
          </div>
        </div>
        <img className="d-block phone-screen" src={image} alt="First slide" />
      </div>
    )
  }


  render() {
    return (
      <div>
        <div style={{ display: 'table', margin: 'auto auto' }}>
          <div id="carouselPhoneFade" className="carousel slide carousel-fade" style={{ width: 'fit-content' }} data-ride="carousel" data-interval="500000">
            <div className="carousel-inner">
              {
                this.data.map((u,i)=>this.renderPhone(u.title, u.phone, u.step, u.details, u.isActive))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default class Home extends Component {
  constructor(props) {
    super(props);
    const lang = Language.CurrentLang;
    this.state = {
      lang
    };
  }
  bank="acb,nama,ocean,baca,gpbank,daia,ocb,lienviet,mb,vib,hdbank,eximbank,sacombank,pgbank,vpbank,maritime,shb,hdbank,donga,vrb,viettinbank,kienlong,bidv,baoviet,vietcombank,vietcapital,vietbank,techcombank,abbank,agribank,navi,scb,seabank,tpbank,westernbank";
  bankList = this.bank.split(',');
  render() {
    return (
      <div>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="4000">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100 home-slide" src={require('../images/Sign up_PmPOS-V6.jpg')} alt="One slide" />
              <div className="overlay"></div>
              <div className="carousel-caption row center-caption">
                <div className="offset-lg-7 right">
                  <div className="custom-title-carousel-header">PayoomPOS</div>
                  <div className="custom-title-carousel-content">Giải pháp thanh toán đơn giản và hiện đại</div>
                  <a className="btn btn-outline-light custom-button-home" role="button" href="#/introduction">Chi tiết</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100 home-slide" src={require('../images/card payment_V6.jpg')} alt="One slide" />
              <div className="overlay"></div>
              <div className="carousel-caption row center-caption">
                <div className="offset-lg-7 right">
                  <div className="custom-title-carousel-header">Thanh toán thẻ</div>
                  <div className="custom-title-carousel-content">An toàn, chấp nhận nhiều loại thẻ</div>
                  <a className="btn btn-outline-light custom-button-home" role="button" href="#/product/card-payment">Chi tiết</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100 home-slide" src={require('../images/Installment payment_V6.jpg')} alt="One slide" />
              <div className="overlay"></div>
              <div className="carousel-caption row center-caption">
                <div className="offset-lg-7 right">
                  <div className="custom-title-carousel-header">Thanh toán trả góp</div>
                  <div className="custom-title-carousel-content">Lãi suất 0%</div>
                  <a className="btn btn-outline-light custom-button-home" role="button" href="#/product/instalment-payment">Chi tiết</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100 home-slide" src={require('../images/QR Code_V6.jpg')} alt="One slide" />
              <div className="overlay"></div>
              <div className="carousel-caption row center-caption">
                <div className="offset-lg-7 right">
                  <div className="custom-title-carousel-header">Thanh toán mã QR</div>
                  <div className="custom-title-carousel-content">Hiện đại và an toàn</div>
                  <a className="btn btn-outline-light custom-button-home" role="button" href="#/product/qr-payment">Chi tiết</a>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>


        <div >

          <div className="row">
            <div className="col-md-12 col-sm-offset-6">
              <h1> <b>PayoomPOS</b><br />Giải pháp thanh toán hiện đại</h1>
            </div>
          </div>
          <div>
            <div className="row" style={{ justifyContent: 'center', marginLeft: 'initial', marginBottom: '50px' }}>
              <div className="col-md-6 col-lg-3 center-col-home">
                <div className="border-item-home">
                  <img className="img-home-style" src={require('../images/thietbi.svg')} alt="" />
                  <div className="font-size-home">Thiết bị đẹp, nhỏ gọn, cho phép di chuyển</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 center-col-home">
                <div className="border-item-home">
                  <img className="img-home-style" src={require('../images/tietkiem.svg')}  alt=""/>
                  <div className="font-size-home">Chi phí đầu tư vận hành thấp, an toàn giao dịch</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 center-col-home">
                <div className="border-item-home">
                  <img className="img-home-style" src={require('../images/giaodien.svg')} alt=""/>
                  <div className="font-size-home">Giao diện thân thiện, thanh toán đơn giản</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 center-col-home">
                <div className="border-item-home">
                  <img className="img-home-style" src={require('../images/hotro.svg')} alt=""/>
                  <div className="font-size-home">Hỗ trợ thanh toán thẻ, thanh toán bằng mã QR, thanh toán trả góp</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <a className="link" href="#/introduction">Tìm hiểu thêm ></a>
        </div>

        <h1><b>{Language.VE("Sử dụng App")}</b></h1>
        <PhoneGuide />

        <h1><b>{Language.VE("Ngân hàng liên kết")}</b></h1>
        <div className="p-1 p-sm-5">
        <div className="row">
        {
          this.bankList.map((u,i)=><div key={i} className="col-4 col-lg-2 col-md-2"><img className="bank-img" src={require(`../images/banks/${u}.png`)} alt={u}></img></div>)
        }

        <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-4"><img className="card-type" src={require(`../images/mastercard.png`)} alt=""></img></div>
          <div className="col-4"><img className="card-type" src={require(`../images/visa.png`)} alt=""></img></div>
          <div className="col-4"><img className="card-type" src={require(`../images/jcb.png`)} alt=""></img></div>
        </div>
        </div>

      </div>
        </div>
      </div>
    );
  }
}