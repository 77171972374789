import Language from '../utils/lang';
import React, { Component } from 'react';
import './footer.css';

export default class Footer extends Component {
    state = {
        lang: Language.CurrentLang
    }

    render() {
        return (
            <div className="footer">
                <div className="footer-top row">
                    <div className="col-xl-3 col-md-6 footer-col-1 px-lg-4" style={{paddingTop: '30px'}}>
                        <div className="text-hotline" style={{fontWeight: 'bold', marginLeft: 20, marginTop: 50 }}>
                            <img src={require('../images/hotline.svg')} width='40' className='mr-4' />
                            1900.54.54.78
                        </div>
                        <div className="text-email" style={{marginLeft: 20, marginTop: 30 }}>
                            <img src={require('../images/email.svg')} width='40' className='mr-4' />
                            support@payoo.vn
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 footer-col-2 px-lg-4">
                        <div className='footer-title'>Trụ sở chính</div>
                        <div style={{lineHeight: '2rem'}}>
                            Tầng 4, Tòa nhà Harbour View, <br/>
                            35 Nguyễn Huệ, Bến Nghé, <br/>
                            Quận 1, TP. Hồ Chí Minh
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <div>
                                <img src={require('../images/phone.svg')} className='mr-4' style={{width: '25px', height: '25px'}}/>
                                (84-28) 3911 7147
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <img src={require('../images/fax.svg')} className='mr-4' style={{width: '25px', height: '25px'}}/>
                                (84-28) 3911 7144
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 footer-col-3 px-lg-4">
                        <div className='footer-title'>Văn phòng đại diện</div>
                        <div style={{lineHeight: '2rem'}}>
                            Tầng 6, Tòa nhà Nelumbo, <br/>
                            114 An Thạch, Cát Linh, <br/>
                            Đống Đa, Hà Nội
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <div>
                                <img src={require('../images/phone.svg')}  className='mr-4' style={{width: '25px', height: '25px'}}/>
                                (84-24) 3736 8629
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <img src={require('../images/fax.svg')} className='mr-4' style={{width: '25px', height: '25px'}}/>
                                (84-24) 3736 8628
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6  footer-col-4' px-lg-4">
                        <div className='footer-title'>Download App</div>
                        <div className="row"> 
                            <div style={{marginTop: '15px', textAlign:'right'}} className="col-6">
                                <a href="https://itunes.apple.com/vn/app/paympos/id1239634013" target="blank">
                                    <img src={require('../images/applestore.png')} className='mr-4' style={{width: '140px'}}/>
                                </a>
                                <br/>
                                <a href="https://play.google.com/store/apps/details?id=vn.payoo.mpos.live" target="blank">
                                    <img src={require('../images/googlestore.png')}  className='mr-4' style={{width: '140px', marginTop: '10px'}}/>
                                </a>
                            </div>

                            <div className="col-6" style={{marginTop: '10px', textAlign:'left'}}>
                                <img className="app-qr" src={require('../images/icon_qr.png')} style={{width: '110px'}} alt="Download mobile app"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom row">
                    <div className="col-lg-4 col-md-4" style={{marginTop: '15px'}} >
                        <div className="margin-icon-bottom">
                            <div className="center-icon-left">
                                <b>Powered by </b>
                                <span style={{marginLeft: '15px'}}>
                                    <img src={require('../images/payoo.png')} style={{width: '100px'}}/>
                                </span>
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-4 col-md-4" ></div> 
                    <div className="col-lg-4 col-md-4 center-icon-right" style={{marginTop: '15px'}} > 
                        <div>
                            <img src={require('../images/cert.png')}/>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}