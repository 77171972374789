import Language from '../utils/lang';
import React, { Component } from 'react';
import './question.css';
import PageBanner from '../components/pageBanner';

export default class Question extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }

    render() {
        return (
            <div>
                <PageBanner src={require("../images/BANNER - questions_V6.jpg")}>
                    <span>{Language.VE("Các câu hỏi thường gặp", "")}
                        <br className="d-block d-sm-none" />
                    </span>
                </PageBanner>

                <div className="col-lg-12 col-md-12" style={{marginTop: '80px'}}>
                    <ul className="icon-ul-question">
                        <li className="text-align-left">
                            <div style={{lineHeight:'2rem'}}>
                                <b>Khi gặp sự cố, liên hệ với ai để xử lý?</b>
                                <div>
                                    Khi gặp sự cố, quý khách hàng xin vui lòng liên hệ với bộ phận hỗ trợ khách hàng Payoo, số hotline <span className="phone">1900545478</span>, hoạt động 24/24.
                                </div>
                            </div>
                        </li>
                        <li className="text-align-left">
                            <div style={{lineHeight:'2rem'}}>
                                <b>PayoomPOS chấp nhận những loại thẻ nào?</b>
                                <div>
                                    PayoomPOS chấp nhận thanh toán đối với tất cả các loại thẻ từ, chip được phát hành trong nước và quốc tế.
                                </div>
                            </div>
                        </li>
                        <li className="text-align-left">
                            <div style={{lineHeight:'2rem'}}>
                                <b>DVCNT có thể chủ động/ tự động tổng kết các giao dịch để hạn chế việc Void của nhân viên?</b>
                                <div>
                                    Khi giao dịch đã tổng kết, nhân viên sẽ không thể hủy giao dịch được. ĐVCNT có thể chủ động tổng kết ở chức năng lịch sử giao dịch hoặc hệ thống tự động tổng kết giao dịch vào 12h hằng ngày. 
                                </div>
                            </div>
                        </li>
                        <li className="text-align-left">
                            <div style={{lineHeight:'2rem'}}>
                                <b>Khi đầu đọc thẻ hết pin, làm thế nào để nạp lại?</b>
                                <div>
                                    App thanh toán PayoomPOS có chức năng hiển thị % PIN của dongle, trong trường hợp dongle yếu pin hoặc hêt pin sẽ không thể thực hiện thanh toán, Nhân viên cửa hàng có thể dùng cáp sạc microusb để sạc cho dongle
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}