import Language from '../utils/lang';
import React, { Component } from 'react';
import PageBanner from '../components/pageBanner';
import './introduction.css';

export default class Introduction extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }

    render() {
        return (
            <div>
            <PageBanner src={require("../images/BANNER - About_V6.jpg")}>
                <div>
                    <b className="ml-3">{Language.VE("PayoomPOS", "")}</b> 
                </div>
                <div>
                    <span>{Language.VE("Giải pháp thanh toán hiện đại", "")}</span>
                </div>
            </PageBanner>
            <h1>{Language.VE("PayoomPOS là gì?", "Register Process")}</h1>
            <div className="container">
               <div style={{fontSize: '24px', textAlign: 'justify'}}>
                <div>{Language.VE("PayoomPOS là giải pháp thanh toán ra đời dưới sự hợp tác giữa VietUnion với tập đoàn viễn thông và công nghệ thông tin hàng đầu Nhật Bản - NTT Data. PayoomPOS cung cấp giải pháp thanh toán bằng thẻ qua điện thoại thông minh (Smartphone) và thiết bị đọc thẻ đi kèm. ", "Register Process")}
                </div> <br/>

                <div>
                        Giải pháp PayoomPOS được kỳ vọng sẽ thay thế cho các thiết bị quẹt thẻ truyền thống đang có trên thị trường
                         vì những tiện lợi, hiệu quả tối ưu của nó.
                </div>
               </div>
            </div>

            <hr className="hr-introduction"></hr>

            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                        <ul className="icon-ul">
                            <li className="text-align-justify">
                                Thiết bị đẹp, nhỏ gọn, chuyên dụng, cho phép di chuyển nhiều nơi.
                            </li>
                            <li className="text-align-justify">
                                Chi phí đầu tư, vận hành thấp. Đảm bảo tính an toàn trong giao dịch.
                            </li>
                            <li className="text-align-justify">
                                Quá trình thanh toán nhanh chóng, đơn giản và thực hiện được mọi lúc mọi nơi.
                            </li>
                            <li className="text-align-justify">
                                Không phải quản lý tiền mặt, không lo phiền phức về tiền lẻ.
                            </li>
                            <li className="text-align-justify">
                                Hỗ trợ thanh toán trả góp, giúp khách hàng có nhiều lựa chọn trong việc chi tiêu, mua sắm.
                            </li>
                            <li className="text-align-justify">
                                Tích hợp với hệ thống của đối tác để truy vấn đơn hàng, hiển thị thông tin đơn hàng trên ứng dụng.
                            </li>
                            <li className="text-align-justify">
                                Chấp nhận đa dạng các loại thẻ nội địa (ATM), quốc tế (Master, Visa, JCB…) và thanh toán QR.
                            </li>
                            <li className="text-align-justify">
                                <div>
                                    <span>Hotline:</span><span className="phone">1900.54.54.78</span>
                                    <span>và email:</span><span className="phone">support@payoo.vn</span>
                                    <span> luôn sẵn sàng để hỗ trợ kịp thời.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 order-first order-lg-2">
                            <img style={{maxWidth: '100%', maxHeight: '650px'}} src={require('../images/dongle.png')} alt="One slide"/>
                    </div>
                </div>
            </div>

            <hr className="hr-introduction"></hr>

            <div className="col-lg-12 col-md-12">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5 col-md-5">
                        <img style={{maxWidth: '100%', maxHeight: '650px'}} src={require('../images/business.jpg')} alt="One slide"/>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div style={{fontSize: '20px',  textAlign: "justify"}}>
                            Với những ưu điểm vượt trội, giải pháp thanh toán PayoomPOS 
                            có thể ứng dụng ở bất kỳ đâu có phát sinh giao dịch, thích hợp đối với:
                        </div>
                        <ul className="icon-ul-check">
                            <li className="text-align-justify">
                                Doanh nghiệp kinh doanh có tính di động, số lượng nhân viên nhiều: giao hàng, taxi,...
                            </li>
                            <li className="text-align-justify">
                                Doanh nghiệp kinh doanh muốn chuyển đổi sang hình thức hiện đại PayoomPOS như: Nhà hàng, khách sạn, siêu thị,...
                            </li>
                            <li className="text-align-justify">
                                Doanh nghiệp kinh doanh nhỏ lẻ, không đáp ứng yêu cầu để triển khai POS: Shop online, quần áo, mỹ phẩm,...
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}