import Language from '../utils/lang';
import React, { Component } from 'react';
import './payInstallment.css';
import PageBanner from '../components/pageBanner';
import BackgroundImage1 from '../images/imgpsh_fullsize.png';
import BackgroundImage3 from '../images/imgpsh_fullsize_2.png';

export default class PayInstallment extends Component {
    constructor(props) {
        super(props);
        const lang = Language.CurrentLang;
        this.state = {
            lang
        };
    }
    bank = "citi,eximbank,fe,hsbc,kienlong,maritime,nama,ocb,sacombank,scb,seabank,shinhan,standard,techcombank,tpbank,vib,viettinbank,vpbank";
    bankList = this.bank.split(',');
    render() {
        return (
            <div>
                <PageBanner src={require("../images/BANNER - installment payment_V6.jpg")}>
                    <span>{Language.VE("Thanh toán", "")}
                        <br className="d-block d-sm-none" />
                        <b className="ml-sm-3">{Language.VE("Trả góp", "")}</b>
                    </span>
                </PageBanner>

                <div className="p-t-10rem">
                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1" style={{
                            backgroundImage: `url(${BackgroundImage1})`, backgroundRepeat: 'no-repeat',
                            paddingBottom: '100px', backgroundSize: 'auto 100%'
                        }}>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img style={{ maxWidth: '100%', maxHeight: '500px', marginBottom: '30px' }} src={require('../images/1.login.jpg')} />
                                    </div>
                                    <div className="col-md-6">
                                        <img style={{ maxWidth: '100%', maxHeight: '500px' }} src={require('../images/2.home.jpg')} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-1">
                                        <div>
                                            <h1 className="h1-pay-card">Bước 1</h1>
                                        </div>
                                        <div className="text-align-step-1">
                                            <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/1. dangnhap.svg')} />
                                            <div style={{ fontSize: '20px', marginTop: '10px' }}>Đăng nhập vào tài khoản PayoomPOS.<br /> Chọn giao dịch thanh toán.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-l-10rem">
                        <div className="row offset-lg-1">
                            <div className="col-lg-3">
                                <div className="text-align-step-2">
                                    <div>
                                        <h1 className="h1-pay-card">Bước 2</h1>
                                    </div>
                                    <div className="text-align-step-2">
                                        <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/4. cathe.svg')} alt="One slide" />
                                        <div style={{ fontSize: '20px', marginTop: '10px' }}>Nhập số tiền và thông tin kỳ hạn trả góp.<br /> Cà thẻ.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <img style={{ maxWidth: '100%', maxHeight: '600px', marginBottom: '30px' }} src={require('../images/phone-05.jpg')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 p-l-10rem p-t-step-3 style-image-step-3" style={{ backgroundImage: `url(${BackgroundImage3})` }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <img className="img-style-step-3-pay-installment" src={require('../images/9.ky ten.jpg')} alt="One slide" />
                            </div>
                            <div className="col-lg-6 order-first order-lg-2 p-r-10rem">
                                <div className="offset-lg-4">
                                    <div className="text-align-step-3">
                                        <div>
                                            <h1 className="h1-pay-installment" style={{ marginTop: '0' }}>{Language.VE("Bước 3")}</h1>
                                        </div>
                                        <div className="text-align-step-3 pr-5">
                                            <img style={{ width: '100px', maxHeight: '100px' }} src={require('../images/3. kyten.svg')} alt="One slide" />
                                            <div style={{ fontSize: '20px', marginTop: '10px' }}>Ký tên xác nhận và hoàn tất giao dịch.<br /> Nhận thông tin đăng ký trả góp
                                            qua email và điện thoại đăng ký.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12" style={{ marginTop: '80px' }}>
                        <div className="col-lg-4 offset-lg-4 banner-content-product" style={{ textAlign: 'center' }}>
                            <ul className="icon-ul">
                                <li className="text-align-left">
                                    {Language.VE("Hỗ trợ đăng ký trả góp với ngân hàng nhanh chóng.")}
                                </li>
                                <li className="text-align-left">
                                    {Language.VE("Thúc đẩy doanh thu, mở rộng đối tượng khách hàng.")}
                                </li>
                                <li className="text-align-left">
                                    {Language.VE("Lãi xuất thấp.")}
                                </li>
                                <li className="text-align-left">
                                    {Language.VE("Hỗ trợ trả góp nhiều ngân hàng.")}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <div className="col-lg-12">
                            <img className="mockup-image" src={require('../images/Website Mockup _V6.jpg')} />
                        </div>
                    </div>

                    <div>
                        <h1><b>{Language.VE("Ngân hàng liên kết trả góp")}</b></h1>
                        <div className="p-1 p-sm-5">
                            <div className="row">
                                {
                                    this.bankList.map((u, i) => <div key={i} className="col-4 col-lg-2 col-md-2"><img className="bank-img" src={require(`../images/banks/${u}.png`)} alt={u}></img></div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}