import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import Menu from './components/menu';
import Language from './utils/lang';
import Introduction from './pages/introduction';
import Registration from './pages/registration';
import Manual from './pages/manual';
import PayCard from './pages/payCard';
import PayInstallment from './pages/payInstallment';
import PayQR from './pages/payQr';
import Home from './pages/home';
import Footer from './components/footer';
import { createBrowserHistory } from 'history'
import Login from './pages/login';
import {AnimatedSwitch, spring } from 'react-router-transition';
import Question from './pages/question';

const Navigation = [
    {
        name: 'home',
        link: '/',
        isHome: true,
        component: Home
    },
    {
        name: 'introduction',
        link: '/introduction',
        component: Introduction
    },
    {
        name: 'product',
        link: '/product',
        subNavigation: [
            {
                name: 'payCard',
                link: '/product/card-payment',
                component: PayCard
            },
            {
                name: 'payInstallment',
                link: '/product/instalment-payment',
                component: PayInstallment
            },
            {
                name: 'payQR',
                link: '/product/qr-payment',
                component: PayQR
            }
        ]
    },
//    {
//         name: 'flowRegister',
//         link: '/register',
//         component: Registration
//    },
    {
        name: 'userManual',
        link: '/manual',
        component: Manual,
        hide: true
    },
    {
        name: 'login',
        link: '/login',
        component: Login,
        hide: true
    },
    {
        name: 'question',
        link: '/question',
        component: Question,
        hide: true
    }
];

const NavigationPath = [];
Navigation.forEach(u => u.subNavigation ? u.subNavigation.forEach(v => NavigationPath.push(v)) : NavigationPath.push(u));
console.log(NavigationPath);

class App extends Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory()
        this.history.listen(_ => {
            window.scrollTo(0, 0)
        });
        const lang = Language.CurrentLang;
        this.state = {
            lang
        }
    }
    componentWillMount() {
    }
    mapStyles(styles) {
        return {
            opacity: styles.opacity,
            transform: `translateY(${-styles.y}px)`,
        };
    }
    bounce(val) {
        return spring(val,{
            stiffness: 1000,
            damping: 100,
            precision:100
        })
        return val;
    }
    bounceTransition = {
        atEnter: {
            opacity: 0,
            y: 100,
        },
        atLeave: {
            opacity: 1,
            y: 0,
        },
        atActive: {
            opacity: this.bounce(1),
            y: this.bounce(0),
        },
    };

    render() {

        return (
            <Router history={this.history}>
                <div className="App">
                    <Menu navigation={Navigation} />
                    <div>
                        <AnimatedSwitch
                        atEnter={this.bounceTransition.atEnter}
                        atLeave={this.bounceTransition.atLeave}
                        atActive={this.bounceTransition.atActive}
                        mapStyles={this.mapStyles}
                        className="route-wrapper"
                        >
                            {
                                NavigationPath.map(u => (
                                    <Route exact path={u.link} component={u.component} key={u.link} />
                                ))
                            }
                            <Redirect to="/" />
                        </AnimatedSwitch>
                    </div>
                    <Footer></Footer>
                </div>
            </Router >
        );
    }
}

export default App;
