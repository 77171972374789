import Language from '../utils/lang';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import './menu.css'

export default class Menu extends Component {
    state = {
        lang: Language.CurrentLang,
        isPwa: window.matchMedia('(display-mode: standalone)').matches
    }

    menuClick = event => {
        this.refs.navbarSupportedContent.classList.remove("show");
    }

    renderMenu() {
        return (

            this.props.navigation.filter(u => !u.hide).map((u, i) => (!u.isHome && (u.subNavigation ?
                (
                    <li className="nav-item dropdown" key={i}>
                        <NavLink className="nav-link dropdown-toggle" to={u.link} id={"navbarDropdown" + i} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.lang[u.name]}</NavLink>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                            {
                                u.subNavigation.map((v, j) => (
                                    <NavLink to={v.link} key={j} className="dropdown-item" onClick={this.menuClick.bind(this)}>
                                        {this.state.lang[v.name]}
                                    </NavLink>
                                ))
                            }
                        </div>
                    </li>
                )
                :
                (
                    <li className="nav-item" key={i}>
                        <NavLink to={u.link} className="nav-link" onClick={this.menuClick.bind(this)}>
                            {this.state.lang[u.name]}
                        </NavLink>
                    </li>
                )
            )))
        )
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="#" onClick={this.menuClick.bind(this)}>
                    <img src={require('../images/logo_PayoomPOS.png')} width='150' />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent" ref="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {
                            this.renderMenu.bind(this)()
                        }
                    </ul>

                    <div className="form-inline my-2 my-lg-0" style={{ color: 'rgb(58,139,193)', display: 'block' }}>
                        {
                            !this.state.isPwa &&
                            (
                                <span>
                                    <a className="my-2 my-lg-0 mr-2 link" href="#/login" onClick={this.menuClick.bind(this)}>Đăng nhập</a>
                                    {/*<span className="ml-2 mr-3">|</span>*/}
                                </span>
                            )
                        }
                        {/* <Link smooth className="my-2 my-lg-0 mr-2 link" to="/register#form" onClick={this.menuClick.bind(this)}>Đăng ký</Link>*/}
                    </div>
                </div>


            </nav >
        );
    }
}
